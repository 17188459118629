import Vue from "vue";

import { L10n, enableRipple, loadCldr, setCulture } from "@syncfusion/ej2-base";
import { ButtonPlugin, SwitchPlugin } from "@syncfusion/ej2-vue-buttons";
import { DialogPlugin, TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import {
  DropDownListPlugin,
  MultiSelectPlugin,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";
import {
  TextBoxPlugin,
  ColorPickerPlugin,
  NumericTextBoxPlugin,
  UploaderPlugin,
  MaskedTextBoxPlugin,
} from "@syncfusion/ej2-vue-inputs";
import {
  TreeViewPlugin,
  TabPlugin,
  ContextMenuPlugin,
} from "@syncfusion/ej2-vue-navigations";
import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
import {
  CalendarPlugin,
  DatePickerPlugin,
  TimePickerPlugin,
  DateRangePickerPlugin,
} from "@syncfusion/ej2-vue-calendars";
import { SchedulePlugin } from "@syncfusion/ej2-vue-schedule";
// import { ToastPlugin } from "@syncfusion/ej2-vue-notifications";

import * as gregorian from "cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "cldr-data/main/ko/numbers.json";
import * as currencies from "cldr-data/main/ko/currencies.json";
import * as timeZoneNames from "cldr-data/main/ko/timeZoneNames.json";

Vue.use(ButtonPlugin);
Vue.use(DialogPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(ComboBoxPlugin);
Vue.use(TextBoxPlugin);
Vue.use(ColorPickerPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(UploaderPlugin);
Vue.use(TreeViewPlugin);
Vue.use(TabPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(CalendarPlugin);
Vue.use(DatePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(SwitchPlugin);
Vue.use(MaskedTextBoxPlugin);
Vue.use(SchedulePlugin);
Vue.use(TooltipPlugin);
Vue.use(ContextMenuPlugin);
// Vue.use(ToastPlugin);
Vue.use(DateRangePickerPlugin);

L10n.load({
  // Scheduler 팝업 다국어 처리
  ko: {
    schedule: {
      day: "일",
      week: "주",
      workWeek: "일주일",
      month: "달",
      agenda: "의제",
      weekAgenda: "주 일정",
      workWeekAgenda: "일주일 의제",
      monthAgenda: "달 의제",
      today: "오늘",
      noEvents: "이벤트가 없습니다",
      emptyContainer: "이 날에는 예정된 행사가 없습니다.",
      allDay: "하루 종일",
      start: "시작",
      end: "종료",
      more: "더",
      close: "닫기",
      cancel: "취소",
      noTitle: "(제목 없음)",
      delete: "삭제",
      deleteEvent: "이번 행사",
      deleteMultipleEvent: "여러 이벤트 삭제",
      selectedItems: "선택된 아이템",
      deleteSeries: "전체 시리즈",
      edit: "편집하다",
      editSeries: "전체 시리즈",
      editEvent: "이번 행사",
      createEvent: "창조하다",
      subject: "제목",
      addTitle: "제목 추가",
      moreDetails: "자세한 내용은",
      save: "저장",
      editContent: "시리즈의 약속을 어떻게 바꾸시겠습니까?",
      deleteContent: "이 이벤트를 삭제 하시겠습니까?",
      deleteMultipleContent: "선택한 이벤트를 삭제 하시겠습니까?",
      newEvent: "새로운 이벤트",
      title: "제목",
      location: "위치",
      description: "상세 내용",
      timezone: "시간대",
      startTimezone: "시간대 시작",
      endTimezone: "종료 시간대",
      repeat: "반복",
      saveButton: "저장",
      cancelButton: "취소",
      deleteButton: "삭제",
      recurrence: "회귀",
      wrongPattern: "반복 패턴이 유효하지 않습니다.",
      seriesChangeAlert:
        "이 시리즈의 특정 인스턴스에 대한 변경 사항을 취소하고 전체 시리즈와 다시 일치 시키려고합니까?",
      createError:
        "이벤트 기간은 발생 빈도보다 짧아야합니다. 지속 시간을 줄이거 나 반복 이벤트 편집기에서 반복 패턴을 변경하십시오.",
      sameDayAlert: "같은 날 두 번의 동일한 이벤트가 발생할 수 없습니다.",
      editRecurrence: "반복 편집",
      repeats: "반복",
      alert: "경보",
      startEndError: "선택한 종료 날짜는 시작 날짜 이전에 발생합니다.",
      invalidDateError: "입력 한 날짜 값이 유효하지 않습니다.",
      blockAlert: "차단 된 시간 범위 내에서 이벤트를 예약 할 수 없습니다.",
      ok: "확인",
      yes: "예",
      no: "아니",
      occurrence: "발생",
      series: "시리즈",
      previous: "이전",
      next: "다음",
      timelineDay: "타임 라인 데이",
      timelineWeek: "타임 라인 주",
      timelineWorkWeek: "타임 라인 작업 주간",
      timelineMonth: "타임 라인 월",
      timelineYear: "타임 라인",
      editFollowingEvent: "다음 행사",
      deleteTitle: "이벤트 삭제",
      editTitle: "이벤트 수정",
      beginFrom: "시작",
      endAt: "종료",
    },
    recurrenceeditor: {
      none: "없음",
      daily: "매일",
      weekly: "주간",
      monthly: "월간 간행물",
      month: "달",
      yearly: "매년",
      never: "안함",
      until: "까지",
      count: "카운트",
      first: "먼저",
      second: "둘째",
      third: "제삼",
      fourth: "네번째",
      last: "마지막",
      repeat: "반복",
      repeatEvery: "매번 반복",
      on: "반복",
      end: "종료",
      onDay: "일",
      days: "일",
      weeks: "주",
      months: "월",
      years: "연령)",
      every: "...마다",
      summaryTimes: "타임스)",
      summaryOn: "의 위에",
      summaryUntil: "...까지",
      summaryRepeat: "반복",
      summaryDay: "일",
      summaryWeek: "주",
      summaryMonth: "월",
      summaryYear: "연령)",
      monthWeek: "월 주",
      monthPosition: "월 위치",
      monthExpander: "월 확장기",
      yearExpander: "년 확장기",
      repeatInterval: "간격 반복",
    },
    calendar: {
      today: "오늘",
    },
    grid: {
      StartsWith: "입력값으로 시작",
      EndsWith: "입력값으로 끝",
      Contains: "입력값 포함",
      Equal: "같은값",
      NotEqual: "다른값",
      GreaterThan: "초과",
      GreaterThanOrEqual: "이상",
      LessThan: "미만",
      LessThanOrEqual: "이하",
    },
    daterangepicker: {
      startLabel: "시작일",
      endLabel: "종료일",
      applyText: "적용",
      cancelText: "취소",
      selectedDays: "선택일수",
    },
  },
});

// syncfusion localization
loadCldr(
  currencies.default,
  gregorian.default,
  numbers.default,
  timeZoneNames.default
); // webpack 4에서는 default로 packaing 되므로 이와 같이 처리해야 한다

setCulture("ko");

// syncfusion 체크
enableRipple(true);
